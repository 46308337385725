var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100vw","height":"100vh","background-color":"#f9f9f9"}},[_c('div',{staticClass:"ma-0",staticStyle:{"position":"absolute","left":"50%","top":"30%","transform":"translate(-50%, -50%)","width":"100%","max-width":"1000px"}},[(
        !_vm.$vuetify.breakpoint.xsOnly &&
        !_vm.$vuetify.breakpoint.smOnly &&
        !_vm.$vuetify.breakpoint.mdOnly
      )?_c('v-row',{staticClass:"ma-0",attrs:{"align":"start"}},[_c('div',{staticClass:"mt-8 pt-2 text-container"},[_c('v-img',{staticClass:"mt-1 mb-7 pl-2",attrs:{"max-width":"210","src":require("@/assets/logo/alinocam/text_medium.png")}}),_c('b',{staticStyle:{"font-size":"26px"}},[(_vm.$route.query == null || _vm.$route.query.type == null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.notFound.title')))]):(_vm.$route.query.type == 'link')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.link.title')))]):_vm._e()]),_c('div',{staticClass:"mt-2 text-details"},[(_vm.$route.query == null || _vm.$route.query.type == null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.notFound.text'))+" ")]):(_vm.$route.query.type == 'link')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.link.text'))+" ")]):_vm._e()]),_c('TextButton',{staticClass:"mt-3",attrs:{"buttonColor":"primary","icon":"mdi-chevron-left","text":_vm.$t('nirvana.button.toLanding')},on:{"click":function($event){return _vm.$router.push({
              name: _vm.isLoggedIn ? 'project_overview' : 'login',
              params: {lang: _vm.$i18n.locale || 'de'},
            })}}})],1),_c('v-spacer'),_c('v-img',{attrs:{"width":"100%","max-width":"400","src":require("@/assets/helper/constructionerror.png")}})],1):_c('v-col',{staticClass:"ma-0 mt-15",staticStyle:{"width":"100%"},attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"mt-8 pt-2 text-container",staticStyle:{"max-width":"500px"}},[_c('v-img',{staticClass:"mt-1 mb-7",attrs:{"max-width":"210","height":"43","src":require("@/assets/logo/alinocam/text_medium.png")}}),_c('b',{staticStyle:{"font-size":"26px"}},[(_vm.$route.query == null || _vm.$route.query.type == null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.notFound.title')))]):(_vm.$route.query.type == 'link')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.link.title')))]):_vm._e()]),_c('div',{staticClass:"mt-2 text-details"},[(_vm.$route.query == null || _vm.$route.query.type == null)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.notFound.text'))+" ")]):(_vm.$route.query.type == 'link')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('nirvana.type.link.text'))+" ")]):_vm._e()]),_c('TextButton',{staticClass:"mt-3",attrs:{"buttonColor":"primary","icon":"mdi-chevron-left","text":_vm.$t('nirvana.button.toLanding')},on:{"click":function($event){return _vm.$router.push({
              name: _vm.isLoggedIn ? 'project_overview' : 'login',
              params: {lang: _vm.$i18n.locale || 'de'},
            })}}})],1)])],1),_c('v-row',{staticClass:"ma-0 mb-1",staticStyle:{"position":"absolute","bottom":"5px","left":"50%","transform":"translateX(-50%)","width":"100%"},attrs:{"align":"center","justify":"center"}},[_c('a',{staticClass:"links",attrs:{"href":_vm.$t('auth.privacy.url')}},[_c('span',{staticClass:"links"},[_vm._v(_vm._s(_vm.$t('auth.privacy.text')))])]),_c('a',{staticClass:"links",attrs:{"href":_vm.$t('auth.imprint.url')}},[_c('span',{staticClass:"links ml-5"},[_vm._v(_vm._s(_vm.$t('auth.imprint.text')))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }