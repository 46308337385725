<template>
  <div style="width: 100vw; height: 100vh; background-color: #f9f9f9">
    <div
      class="ma-0"
      style="
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 1000px;
      "
    >
      <v-row
        v-if="
          !$vuetify.breakpoint.xsOnly &&
          !$vuetify.breakpoint.smOnly &&
          !$vuetify.breakpoint.mdOnly
        "
        class="ma-0"
        align="start"
      >
        <div class="mt-8 pt-2 text-container">
          <v-img
            class="mt-1 mb-7 pl-2"
            max-width="210"
            src="@/assets/logo/alinocam/text_medium.png"
          />

          <b style="font-size: 26px">
            <span v-if="$route.query == null || $route.query.type == null">
              {{ $t('nirvana.type.notFound.title') }}</span
            >
            <span v-else-if="$route.query.type == 'link'">
              {{ $t('nirvana.type.link.title') }}</span
            >
          </b>

          <div class="mt-2 text-details">
            <span v-if="$route.query == null || $route.query.type == null">
              {{ $t('nirvana.type.notFound.text') }}
            </span>
            <span v-else-if="$route.query.type == 'link'">
              {{ $t('nirvana.type.link.text') }}
            </span>
          </div>

          <TextButton
            v-on:click="
              $router.push({
                name: isLoggedIn ? 'project_overview' : 'login',
                params: {lang: $i18n.locale || 'de'},
              })
            "
            buttonColor="primary"
            icon="mdi-chevron-left"
            class="mt-3"
            :text="$t('nirvana.button.toLanding')"
          />
        </div>

        <v-spacer />

        <v-img
          width="100%"
          max-width="400"
          src="@/assets/helper/constructionerror.png"
        />
      </v-row>

      <v-col
        v-else
        class="ma-0 mt-15"
        align="center"
        justify="center"
        style="width: 100%"
      >
        <div class="mt-8 pt-2 text-container" style="max-width: 500px">
          <v-img
            class="mt-1 mb-7"
            max-width="210"
            height="43"
            src="@/assets/logo/alinocam/text_medium.png"
          />

          <b style="font-size: 26px">
            <span v-if="$route.query == null || $route.query.type == null">
              {{ $t('nirvana.type.notFound.title') }}</span
            >
            <span v-else-if="$route.query.type == 'link'">
              {{ $t('nirvana.type.link.title') }}</span
            >
          </b>

          <div class="mt-2 text-details">
            <span v-if="$route.query == null || $route.query.type == null">
              {{ $t('nirvana.type.notFound.text') }}
            </span>
            <span v-else-if="$route.query.type == 'link'">
              {{ $t('nirvana.type.link.text') }}
            </span>
          </div>
          <TextButton
            v-on:click="
              $router.push({
                name: isLoggedIn ? 'project_overview' : 'login',
                params: {lang: $i18n.locale || 'de'},
              })
            "
            buttonColor="primary"
            icon="mdi-chevron-left"
            class="mt-3"
            :text="$t('nirvana.button.toLanding')"
          />
        </div>
      </v-col>
    </div>

    <v-row
      class="ma-0 mb-1"
      align="center"
      justify="center"
      style="
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      "
    >
      <a :href="$t('auth.privacy.url')" class="links"
        ><span class="links">{{ $t('auth.privacy.text') }}</span></a
      >
      <a :href="$t('auth.imprint.url')" class="links"
        ><span class="links ml-5">{{ $t('auth.imprint.text') }}</span></a
      >
    </v-row>
  </div>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';

export default {
  name: '404View',
  components: {TextButton},
  data() {
    return {
      isLoggedIn: false,
    };
  },
  async created() {
    this.isLoggedIn = await this.$auth.isLoggedIn();
  },
};
</script>

<style>
.links {
  user-select: none;
  font-size: 14px;
  cursor: pointer !important;
  text-decoration: none;
  color: black;
}
.links:hover {
  color: var(--v-primary-base);
}

.text-container {
  background-color: transparent;
  max-width: 480px;
}

.text-details {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
}
</style>
